.bn:focus{
  outline:0;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-segment {
  min-height: 100vh
}

.custom-img {
  max-height: 20vh
}

.emoji {
  font-weight: 400;
  font-family: apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol;
}

.emoji-table {
	width: 185px !important;
}

.emoji-table tbody {
	display: block;
	overflow-y: scroll !important;
	height: 150px !important;
}

.emoji-punto {
  margin-left: 0.3em;
}

.textemoji {
  font-family: monospace, apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol
}

.instructions-section {
  max-width: 700px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.newsletter-section {
  max-width: 700px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.pateron-img {
	width: 200px;
	height: 100px;
	display: 'inline' !important;
}

@media (min-width: 320px) {
	.pateron-img {
		width: 200px;
		height: 100px;
		display: 'block' !important;
	}
}

@media screen and (max-width: 600px) {
	.footer-section .column {
		display: flex !important;
		flex-direction: column !important;
	}

	.pateron-img {
		margin: 10px 0;
	}
}

@media screen and (min-width: 764px) {
	.steps {
		width: 700px;
	}

	.pateron-img {
		margin: 10px 0;
	}
}
