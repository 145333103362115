/* @font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(fonts/OpenSansEmoji.ttf) format('ttf');
}
 */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:  monospace;
}


textarea {
  font-family:  monospace;
  -webkit-appearance: textarea;
  background-color: white;
  -webkit-rtl-ordering: logical;
  flex-direction: column;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
  padding: 2px;
}

input, textarea, select, button {
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em;
  font: 400 11px system-ui;
}

input, textarea, select, button, meter, progress {
  -webkit-writing-mode: horizontal-tb !important;
}