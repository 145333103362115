/* @font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(fonts/OpenSansEmoji.ttf) format('ttf');
}
 */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:  monospace;
}


textarea {
  font-family:  monospace;
  -webkit-appearance: textarea;
  background-color: white;
  -webkit-rtl-ordering: logical;
  flex-direction: column;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  border-width: 1px;
  border-style: solid;
  border-color: currentColor;
  border-color: initial;
  border-image: none;
  border-image: initial;
  padding: 2px;
}

input, textarea, select, button {
  text-rendering: auto;
  color: #000;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em;
  font: 400 11px system-ui;
}

input, textarea, select, button, meter, progress {
  -webkit-writing-mode: horizontal-tb !important;
}
.bn:focus{
  outline:0;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.custom-segment {
  min-height: 100vh
}

.custom-img {
  max-height: 20vh
}

.emoji {
  font-weight: 400;
  font-family: apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol;
}

.emoji-table {
	width: 185px !important;
}

.emoji-table tbody {
	display: block;
	overflow-y: scroll !important;
	height: 150px !important;
}

.emoji-punto {
  margin-left: 0.3em;
}

.textemoji {
  font-family: monospace, apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol
}

.instructions-section {
  max-width: 700px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.newsletter-section {
  max-width: 700px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.pateron-img {
	width: 200px;
	height: 100px;
	display: 'inline' !important;
}

@media (min-width: 320px) {
	.pateron-img {
		width: 200px;
		height: 100px;
		display: 'block' !important;
	}
}

@media screen and (max-width: 600px) {
	.footer-section .column {
		display: flex !important;
		flex-direction: column !important;
	}

	.pateron-img {
		margin: 10px 0;
	}
}

@media screen and (min-width: 764px) {
	.steps {
		width: 700px;
	}

	.pateron-img {
		margin: 10px 0;
	}
}

